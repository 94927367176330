














import {Component, Vue, Prop} from 'vue-property-decorator';
import {PlayoffSerieTO} from '@/model/ranking.model';
import PlayoffSerie from '@/components/event/PlayoffSerie.vue';
import {GameInfo, roundInfo} from '@/model/game/game.model';

@Component({
  components: {PlayoffSerie},
})
export default class PlayoffSeries extends Vue {

  @Prop({required: true, type: Array}) playoffSeries!: PlayoffSerieTO[];

  title(gameInfo: GameInfo): string {
    return roundInfo(this, gameInfo);
  }
}
