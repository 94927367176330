import { render, staticRenderFns } from "./ImageBox.vue?vue&type=template&id=865e2d9e&scoped=true&functional=true&"
import script from "./ImageBox.vue?vue&type=script&lang=ts&"
export * from "./ImageBox.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "865e2d9e",
  null
  
)

export default component.exports