


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Ranking} from '@/model/ranking.model';

@Component({
  components: {},
})
export default class RankingDisplay extends Vue {

  @Prop({required: true, type: Object}) ranking!: Ranking;

  get columns() {
    return [
      {field: 'rank', label: '#', numeric: true},
      {field: 'team', label: 'Team'},
      {field: 'games', label: 'Sp', numeric: true},
      {field: 'goals', label: 'Tore', centered: true},
      {field: 'pointsLost', label: 'VP', numeric: true},
      {field: 'points', label: 'P', numeric: true},
    ];
  }

  get hasEntries() {
    return this.ranking.entries;
  }

  get emptyMessage() {
    return 'nicht verfügbar';
  }
}
