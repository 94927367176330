























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class BulmaTable extends Vue {

  @Prop({required: true}) rowData!: any[];
  @Prop({required: true}) columns!: any[];
  @Prop({default: true, type: Boolean}) showHeader!: boolean;
  @Prop({default: false}) paginated!: boolean;
  @Prop({default: 0}) pageSize!: number;

  format(value: string, formatter: (params: object) => string): string {
    // noinspection SuspiciousTypeOfGuard
    if (formatter instanceof Function) {
      const params = {value};
      return formatter(params);
    }
    return value;
  }

  cellClass(value: string, cellClass: any) {
    if (cellClass instanceof Function) {
      const params = {value};
      return cellClass(params);
    }
    return cellClass;
  }

}
