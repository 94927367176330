


































import {Component, Prop, Vue} from 'vue-property-decorator';
import {TeamId} from '@/model/core/core-data.model';
import { SET_FOCUS_TEAM_ID, FOCUS_TEAM_ID } from '@/store/context.store';
import { Getter } from 'vuex-class';

@Component({
  components: {},
})
export default class EventTeamSelection extends Vue {

  @Prop({required: true}) teams!: TeamId[];
  @Prop({default: 'Teams'}) title!: string;

  @Getter(FOCUS_TEAM_ID) focusTeamId!: string | undefined;

  get hasTeams(): boolean {
    return this.teams && this.teams.length > 0;
  }

  get oneTeam(): TeamId | undefined {
    if (!this.hasTeams || this.teams.length > 1) {
      return undefined;
    }
    return this.teams[0];
  }

  isActiveTeam(teamId: string): boolean {
    return this.focusTeamId === teamId;
  }

  setActiveTeam(team: TeamId) {
    this.$store.commit(SET_FOCUS_TEAM_ID, team.teamId);
  }

  get selectedDisplay() {
    if (!this.focusTeamId) {
      return this.title;
    }
    const team = this.teams.find(t => t.teamId === this.focusTeamId);
    return team ? team.display : this.title;
  }
}
