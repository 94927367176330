










import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ImageBox extends Vue {
  @Prop({required: true}) src!: string;
  @Prop({required: true}) alt!: string;
}
