






















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameInfo, GameInfoWithRound, roundInfo} from '@/model/game/game.model';
import BulmaTable from '@/components/stats/BulmaTable.vue';
import GameLinks from '@/components/event/GameLinks.vue';
import {COLUMN_FORMATTERS} from '@/services/columns/columns.base';
import {isExhibitionLeague, isNLAB, LeagueId, sortRound, TeamId, isExhibitionGame, isSuisseCup} from '@/model/core/core-data.model';
import {ALL_GROUP_ID, EVENT_CONFIG, FOCUS_TEAM_ID, SELECTED_GROUP_ID, SET_FOCUS_TEAM_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';
import EventTeamSelection from '@/components/event/EventTeamSelection.vue';
import EventGroupSelection from '@/components/event/EventGroupSelection.vue';

@Component({
    components: {EventGroupSelection, EventTeamSelection, BulmaTable},
  })
  export default class EventGameTable extends Vue {

    @Prop({required: true}) games!: GameInfo[];
    @Prop({default: false}) isPreSeason!: boolean;
    @Prop({required: true, type: String}) leagueId!: LeagueId;

    @Prop({required: true, type: Boolean}) hasInternationalTeams!: boolean;
    @Prop({required: true, type: Array}) swissTeams!: TeamId[];
    @Prop({required: true, type: Array}) nonSwissTeams!: TeamId[];
    @Prop({required: true, type: Array}) teamsLowerLeagues!: TeamId[];
    @Prop({required: true, type: Array}) teamsNlSl!: TeamId[];

    @Getter(EVENT_CONFIG) eventConfig!: any;
    @Getter(FOCUS_TEAM_ID) focusTeamId!: string | undefined;
    @Getter(SELECTED_GROUP_ID) groupId!: string;

    readonly initialGroupId = ALL_GROUP_ID;
    groups = [];
    enrichedGames!: GameInfoWithRound[];

    get columns() {
      let dateCol;
      if (!this.paginated && this.isActiveGroup(this.initialGroupId) && !this.focusTeamId) {
        dateCol = 'cur_date';
      } else {
        dateCol = 'date';
      }
      let columns = [
        {field: dateCol, label: 'Datum', valueFormatter: COLUMN_FORMATTERS.dateFormatShort, sortable: this.paginated},
        {field: 'time', label: 'Zeit'},
        {field: 'roundInfo', label: isNLAB(this.leagueId) ? 'Runde' : 'Gruppe'},
        {field: 'homeTeamNameShort', label: 'Heim', cellClass: 'hf-no-wrap'},
        {field: 'awayTeamNameShort', label: 'Gast', cellClass: 'hf-no-wrap'},
        {field: 'result', label: 'Resultat', cellClass: 'hf-no-wrap'},
        {field: 'gameLinks', label: '', cellClass: 'hf-no-wrap', cellRendererFramework: GameLinks},
      ];
      if (this.isPreSeason) {
        columns = columns.filter(c => c.field !== 'roundInfo');
      }
      return columns;
    }

    get showEventGroups() {
      return !isNLAB(this.leagueId) && !isExhibitionLeague(this.leagueId);
    }

    get hasNonSwissTeams() {
      return this.nonSwissTeams && Array.isArray(this.nonSwissTeams) && this.nonSwissTeams.length;
    }

    get isSuisseCup() {
      return isSuisseCup(this.leagueId);
    }

    get paginated() {
      return this.games.length > this.eventConfig.paginationLimit;
    }

    get pageSize() {
      return this.eventConfig.pageSize;
    }

    resetActiveTeam() {
      this.$store.commit(SET_FOCUS_TEAM_ID, '');
    }

    isActiveGroup(groupId: string) {
      return this.groupId === groupId;
    }

    created() {
      this.enrichedGames = this.enrichGames(this.games);
      this.groups = this.initGroups();
    }

    initGroups() {
      const groups: any = {};
      if (!this.showEventGroups) {
        return groups;
      }
      this.enrichedGames
        .map(g => {
          return {
            groupKey: g.round,
            desc: g.roundInfo
          };
        })
        .sort((g1: any, g2: any) => sortRound(g1.groupKey, g2.groupKey))
        .forEach((g: any) => groups[g.groupKey] = g.desc);
      return groups;
    }

    get filteredGames() {
      return this.enrichedGames
        .filter((g: GameInfo) => {
          return (this.groupId === this.initialGroupId || g.round === this.groupId) &&
            (!this.focusTeamId || this.focusTeamId === g.homeTeamId || this.focusTeamId === g.awayTeamId);
        });
    }

    enrichGames(games: GameInfo[]): GameInfoWithRound[] {
      const paginated = this.paginated;
      let lastDate: any = undefined;
      return games
        .filter(gameInfo => this.isPreSeason || !isExhibitionGame(gameInfo.gameTypeId))
        .map(gameInfo => {
          if (!paginated) {
            lastDate = EventGameTable.stripField(gameInfo, 'date', lastDate);
          }
          return {
            ...gameInfo,
            roundInfo: roundInfo(this, gameInfo)
          };
        });
    }

    private static stripField(g: any, field: string, lastValue: any, replacement?: string) {
      const curValue = g[field];
      if (lastValue !== curValue) {
        g[`cur_${field}`] = curValue;
      } else {
        g[`cur_${field}`] = replacement || '';
      }
      return curValue;
    }

  }
