


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameTypeId, TeamId} from '@/model/core/core-data.model';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import {
  FOCUS_LEAGUE_ID,
  FOCUS_SEASON_ID,
  SET_FOCUS_GAME_TYPE_ID,
  SET_FOCUS_LEAGUE_ID,
  SET_FOCUS_SEASON_ID,
  SET_FOCUS_TEAM_ID
} from '@/store/context.store';
import {Getter} from 'vuex-class';

@Component({
  components: {HfTeamLogo},
})
export default class EventTeams extends Vue {

  @Prop({required: true, type: Array}) teams!: TeamId[];
  @Prop({required: false, type: String}) seasonId?: string;
  @Prop({required: false, type: String}) leagueId?: string;
  @Prop({required: false, type: Boolean, default: false}) preSeason!: boolean;

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;

  setActiveTeam(team: TeamId) {
    this.$store.commit(SET_FOCUS_TEAM_ID, team.teamId);
  }

  updateStatsFocusParams(team: TeamId) {
    if (this.seasonId && this.seasonId !== this.focusSeasonId) {
      this.$store.commit(SET_FOCUS_SEASON_ID, this.seasonId);
    }
    if (this.leagueId && this.leagueId !== this.focusLeagueId) {
      this.$store.commit(SET_FOCUS_LEAGUE_ID, this.leagueId);
    }
    const gameTypeId: GameTypeId = 'm';
    this.$store.commit(SET_FOCUS_GAME_TYPE_ID, gameTypeId);
    this.setActiveTeam(team);
  }

  gotoStatsPortal(teamId: TeamId) {
    this.updateStatsFocusParams(teamId);
    this.$router.push({name: 'route-stats-portal'});
  }

}
