






















































import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import PanelBox from '@/components/ui/PanelBox.vue';
import {Box, HfPage} from '@/model/site/components.model';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import {Getter} from 'vuex-class';
import {
  ALL_GROUP_ID,
  CONTEXT,
  EVENT_CONFIG,
  FOCUS_TEAM_ID,
  SELECT_GROUP_ID,
  SELECT_LEAGUE_ID,
  SET_FOCUS_TEAM_ID
} from '@/store/context.store';
import {Context} from '@/model/site/context.model';
import EventService from '@/services/event.service';
import {
  EVENTS,
  GameTypeId,
  isExhibitionPreseason,
  isRealTeam,
  isSwitzerland,
  LeagueId,
  TeamId,
  toTeamId,
  isNLAB
} from '@/model/core/core-data.model';
import COMMON_UTIL, {nil} from '@/utils/common.util';
import SEASON_SERVICE from '@/services/season.service';
import {HfEvent} from '@/model/core/event.model';
import RouteMixin from '@/mixins/route.mixin';
import MetaMixin from '@/mixins/meta.mixin';
import HtmlContent from '@/components/ui/HtmlContent.vue';
import {GameService} from '@/services/game.service';
import {GameInfo} from '@/model/game/game.model';
import EventGameTable from '@/components/event/EventGameTable.vue';
import ImageBox from '@/components/ui/ImageBox.vue';
import EventRanking from '@/components/event/EventRanking.vue';
import GameCarousel from '@/components/games/GameCarousel.vue';
import EventSeasonSelection from '@/components/shared/selection/EventSeasonSelection.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import SelectionTitle from '@/components/ui/SelectionTitle.vue';
import Loading from 'vue-loading-overlay';
import CarouselGameDisplay from '@/components/games/CarouselGameDisplay.vue';
import {Carousel, Slide} from '@noim/vue-carousel';
import MobileMixin from '@/mixins/mobile.mixin';
import EventTeams from '@/components/event/EventTeams.vue';
import RankingDisplay from '@/components/event/RankingDisplay.vue';
import PlayoffSeries from '@/components/event/PlayoffSeries.vue';
import {RankingService} from '@/services/ranking.service';
import {RankingId, Ranking, PlayoffSerieTO} from '@/model/ranking.model';
import {PlayoffService} from '@/services/playoff.service';

const xml2js = require('xml2js');

@Component({
  mixins: [MetaMixin, RouteMixin, MobileMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {
    EventTeams,
    SideBoxes,
    SportLayout,
    EventSeasonSelection,
    GameCarousel,
    EventRanking,
    EventGameTable,
    HfAdBox,
    PanelBox,
    SelectionTitle,
    CarouselGameDisplay,
    Slide,
    Carousel,
    Loading,
    PlayoffSeries
  },
  })
  export default class TournamentPage extends Vue implements HfPage {

    @Prop({required: true}) eventShortcut!: string;
    @Prop() seasonId?: string;

    @Inject('eventService') eventService!: EventService;
    @Inject('gameService') gameService!: GameService;
    @Inject('rankingService') rankingService!: RankingService;
    @Inject('playoffService') playoffService!: PlayoffService;

    @Getter(FOCUS_TEAM_ID) focusTeamId!: string;
    @Getter(CONTEXT) context!: Context;
    @Getter(EVENT_CONFIG) eventConfig!: any;

    event: HfEvent = nil();
    games: GameInfo[] = nil();
    seasonIds: string[] = nil();
    ranking: Ranking = {};
    playoffSeries: PlayoffSerieTO[] = [];

    get title() {
      return this.event && this.event.title;
    }

    get subtitle() {
      return this.event && `${this.event.subtitle}`;
    }

    get headTitle() {
      return `${this.title} | ${this.subtitle}`;
    }

    get headDescription() {
      return this.event && `${this.event.infotext || this.event.modus}`;
    }

    get canonicalUrl() {
      return this.urlFromCurrentPath;
    }

    async created() {
      this.$wait.start('tournamentPage');
      const event = this.eventService.getEventForGameTypeOrLeague(this.ofGameTypeId, this.ofLeagueId);
      if (event) {
        this.setEvent(event);

        const gameTypeId: GameTypeId = 'm';
        const rankingId = new RankingId(this.ofLeagueId, undefined, this.seasonId, gameTypeId);
        this.rankingService.rankingAt(rankingId)
          .then(ranking => this.ranking = ranking)
          .catch(error => Vue.$log.error(`Error loading ranking ${rankingId}`, error));

        this.playoffService.playoffSeriesSeason(this.ofLeagueId, this.ofSeasonId)
          .then(playoffSeries => this.playoffSeries = playoffSeries)
          .catch(error => Vue.$log.error(`Error loading playoff-series ${rankingId}`, error));

      } else {
        const eventData = await this.eventService.loadDefinition(this.ofSeasonId, this.ofGameTypeId, this.ofLeagueId);
        await xml2js.parseStringPromise(eventData, {
          trim: true,
          explicitArray: false,
          async: true
        }).then((data: any) => {
          if (data && data.event) {
            this.setEvent(data.event);
          } else {
            const error = `Von diesem Anlass existieren keine Daten`;
            this.$router.push(`/404?path=${this.$route.fullPath}&message=${error}`);
          }
        }).catch(() => {
        }).finally(() => this.$wait.end('tournamentPage'));
      }

      this.$wait.start('tournamentPage');
      this.eventService.seasonsForEvent(this.ofGameTypeId, this.ofLeagueId)
        .then(seasonIds => this.seasonIds = seasonIds.sort((s1, s2) => -1 * s1.localeCompare(s2)))
        .finally(() => this.$wait.end('tournamentPage'));

      const leagueIdParam = event ? event.leagueId : this.ofLeagueId;
      await this.gameService.byLeagueAndSeason(this.ofSeasonId, leagueIdParam, this.ofGameTypeId)
        .then((games: GameInfo[]) => {
          this.games = games;
          if (!this.games || !this.games.length) {
            // TODO really 404?
            const error = `Von diesem Anlass existieren keine Spiele`;
            this.$router.push(`/404?path=${this.$route.fullPath}&message=${error}`);
          } else {
            this.resetTeam();
          }
        }).finally(() => this.$wait.end('tournamentPage'));
    }

    resetTeam() {
      if (!this.allTeams.find((teamId: TeamId) => this.focusTeamId === teamId.teamId)) {
        this.$store.commit(SET_FOCUS_TEAM_ID, '');
      }
    }

    setEvent(event: HfEvent) {
      this.event = event;
      if (this.ofLeagueId) {
        this.$store.commit(SELECT_LEAGUE_ID, this.ofLeagueId);
      }
      this.$store.commit(SELECT_GROUP_ID, ALL_GROUP_ID);
    }

    get playoffDate(): Date {
      return SEASON_SERVICE.seasonEndDate(this.ofSeasonId);
    }

    get ofSeasonId() {
      let result = this.seasonId;
      if (!result) {
        result = SEASON_SERVICE.currentId();
      }
      return result;
    }

    get ofLeagueId(): LeagueId {
      return EVENTS[this.eventShortcut].leagueId;
    }

    get ofGameTypeId(): GameTypeId | GameTypeId[] {
      return EVENTS[this.eventShortcut].gameTypeId;
    }

    get latestGames() {
      if (!this.games) {
        return undefined;
      }
      const maxGames = this.eventConfig.lastGames || 8;
      const games = this.games
        .filter(g => g.result)
        .sort((g1, g2) => g2.timestamp - g1.timestamp)
        .slice(0, maxGames);
      if (games.length === 0) {
        return undefined;
      }
      return games;
    }

    get boxes(): Box[] {
      const boxes: Box[] = [];
      const ad: Box = {
        component: HfAdBox,
        props: {formatKey: 'rectangle', targets: ['desktop']},
      };

      if (this.event) {
        if (this.event.infologo) {
          const logo: Box = {
            component: ImageBox,
            title: this.event.title,
            props: {src: this.eventLogoSrc, alt: this.eventLogoAlt}
          };
          boxes.push(logo);
        }
        if (this.event.modus) {
          const modus: Box = {
            component: HtmlContent,
            title: 'Modus',
            props: {content: this.event.modus}
          };
          boxes.push(modus);
        }
      }
      if (!this.isExhibitionPreSeason() && !this.isNlSl) {
        const ranking: Box = {
          component: EventRanking,
          props: {
            seasonId: this.ofSeasonId,
            gameTypeId: Array.isArray(this.ofGameTypeId) ? undefined : this.ofGameTypeId
          },
        };
        boxes.push(ranking);
      }
      const leagueEvent = this.eventService.getEventForGameTypeOrLeague(this.ofGameTypeId, this.ofLeagueId);
      if (leagueEvent) {
        if (this.playoffSeries && this.playoffSeries.length) {
          const playoffSeriesBox: Box = {
            component: PlayoffSeries,
            title: 'Play-offs',
            props: {
              playoffSeries: this.playoffSeries
            }
          };
          boxes.push(playoffSeriesBox);
        }

        const rankingDisplay: Box = {
          component: RankingDisplay,
          title: 'Tabelle',
          props: {
            ranking: this.ranking
          }
        };
        if (this.ranking.entries) {
          boxes.push(rankingDisplay);
        }

        const teams: Box = {
          component: EventTeams,
          title: 'Teams',
          props: {
            teams: this.teamsNlSl,
            preSeason: this.isExhibitionPreSeason(),
            seasonId: this.seasonId,
            leagueId: this.ofLeagueId
          }
        };
        boxes.push(teams);
      }
      return [...boxes, ad];
    }

  isExhibitionPreSeason() {
    return isExhibitionPreseason(this.ofGameTypeId, this.ofLeagueId);
  }

  get isNlSl(): boolean {
      return isNLAB(this.ofLeagueId);
  }

  get eventLogoSrc() {
    if (!this.event || !this.event.infologo) {
      return '';
    }
    return COMMON_UTIL.paths.eventLogo(this.event.infologo);
  }

  get eventLogoAlt() {
    return this.event.title;
  }

  get hasGames() {
    return this.games && Array.isArray(this.games) && this.games.length > 0;
  }

  get teamsNlSl(): TeamId[] {
    return this.teamsFiltered((teamId: TeamId) => {
      // NL/SL teams do not start with a number
      return isSwitzerland(teamId) && Number.isNaN(Number.parseInt(teamId.teamId.substr(0, 1)));
    });
  }

  get teamsLowerLeagues(): TeamId[] {
    return this.teamsFiltered((teamId: TeamId) => {
      // Teams in lower leagues starts with a number, e.g. 12_aro
      return isSwitzerland(teamId) && Number.isInteger(Number.parseInt(teamId.teamId.substr(0, 1)));
    });
  }

    get nonSwissTeams(): TeamId[] {
      return this.teamsFiltered((teamId: TeamId) => !isSwitzerland(teamId));
    }

    get swissTeams(): TeamId[] {
      return this.teamsFiltered((teamId: TeamId) => isSwitzerland(teamId));
    }

    get hasInternationalTeams() {
      return this.nonSwissTeams && this.nonSwissTeams.length > 0;
    }

    get allTeams() {
      return this.teamsFiltered((teamId: TeamId) => true);
    }

    private teamsFiltered(filterFunc: ((t: TeamId) => boolean)): TeamId[] {
      if (!this.games) {
        return [];
      }
      const homeTeams = this.games.map(g => toTeamId(g.homeTeamId, g.homeTeamNameShort || g.homeTeamName, g.homeTeamCountryId));
      const awayTeams = this.games.map(g => toTeamId(g.awayTeamId, g.awayTeamNameShort || g.awayTeamName, g.awayTeamCountryId));
      const teams: TeamId[] = [];
      [...homeTeams, ...awayTeams].filter(isRealTeam).filter(filterFunc).forEach(t => {
        if (!teams.find((teamId: TeamId) => t.teamId === teamId.teamId)) {
          teams.push(t);
        }
      });
      return teams.sort((t1: TeamId, t2: TeamId) => t1.display.localeCompare(t2.display));
    }

  }
