


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {SELECT_GROUP_ID, SELECTED_GROUP_ID, ALL_GROUP_ID} from '@/store/context.store';

@Component({
  components: {},
})
export default class EventGroupSelection extends Vue {

  @Prop({required: true}) groups!: any;
  @Prop({required: true}) initialGroupId!: string;
  @Prop({default: 8}) tabLimit!: number;

  @Getter(SELECTED_GROUP_ID) groupId!: string;

  get displayTabs() {
    return this.groupKeys.length <= this.tabLimit;
  }

  setActive(groupId: string) {
    this.$store.commit(SELECT_GROUP_ID, groupId);
  }

  isActiveGroup(groupId: string) {
    return this.groupId === groupId;
  }

  get allGroupId() {
    return ALL_GROUP_ID;
  }

  get groupKeys() {
    return [this.initialGroupId, ...Object.keys(this.groups)];
  }
}
